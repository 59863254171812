
.moreTop{
    background-color: rgb(255, 255, 255);
    height: 6rem;
    margin-top: 0.2rem;
}

.moreCz{
    background-image: url("../imgs/more/cz.png");
    width: 100%;
    height: 45px;
    background-size: 100%,100%;
    bottom: 0;
    position: fixed
}
.textCz{
    margin-left: 0.5rem;
    font-size: 15px;
    font-weight: 700;
    padding-top: 0.2rem;
}

.am-list-item .am-input-label.am-input-label-5 {
     width: 40px;
}
.am-list-item .am-input-control .fake-input-container .fake-input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 50px;
    margin-right: 5px;
    color: #000;
    font-size: 39px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.morejq{
    text-align: center;
    border: 1px solid #B2B2B2;
    width: 60px;
    height: 40px;
    font-weight: 700;
    line-height: 40px;
}





