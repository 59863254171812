
.rl {
  text-align: center;
    margin-top: 0.2rem;
}
.timeSj{
    height: 40px;
    width: 140px;
    background-color: #FFD666;
    margin: 0.4rem 0.5rem 0.5rem 0.4rem;
    border-radius: 20% / 70% 70% 70% 70%;
}
.timejiage{
    margin: 0.2rem 0rem 0.5rem 0.2rem;
    font-size: 13px;
}
.timeQue{
    background-image: url("../imgs/time/zrzf.png");
    width: 100%;
    height: 45px;
    background-size: 100%,100%;
    bottom: 0;
    position: fixed
}






