.am-tabs-tab-bar-wrap {
    flex-shrink: 0;
    bottom: 0;
    position: fixed;
    width: 100%;
    z-index: 98
}

.am-list-item img {
    width: 50px;
    height: 50px;
    vertical-align: middle;
}

.top {
    background-color: #fff;
    color: #108ee9;
    top: 0;
    position: fixed;
    width: 100%;
    z-index: 98;
}

.am-tab-bar {
    overflow: hidden;
    padding-bottom: 12%;
    padding-top: 11%;
}

.am-badge-text {
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: absolute;
    top: 0.69rem;
    left: 3.5rem;
    height: 18px;
    line-height: 18px;
    min-width: 9px;
    border-radius: 12px;
    padding: 0 5px;
    text-align: center;
    font-size: 12px;
    color: #fff;
    background-color: #ff5b05;
    white-space: nowrap;
    transform: translateX(-45%);
    transform-origin: -10% center;
    z-index: 10;
    font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "\5FAE\8F6F\96C5\9ED1", SimSun, sans-serif;
}

.bsx {
    width: 26px;
    height: 26px;
    background: url(../imgs/bsx.png) center center /  26px 26px no-repeat
}

.bsm {
    width: 26px;
    height: 26px;
    background: url(../imgs/bsm.png) center center /  26px 26px no-repeat
}

.fabu {
    width: 26px;
    height: 26px;
    background: url(../imgs/fabu.png) center center /  26px 26px no-repeat
}

.am-tab-bar-bar .am-tab-bar-tab-icon .tab-badge :last-child {
    left: 22px;
    top: -25%;
}

.am-list-item1 {
    position: relative;
    display: flex;
    padding-left: 15px;
    min-height: 44px;
    vertical-align: middle;
    overflow: hidden;
    background-color: #DDF3FE;
    transition: background-color 200ms;
    align-items: center;
}

.meImg {
    width: 22px !important;
    height: 22px !important;
}

.mine_icon {
    width: 28px;
    height: 20px
}

.mine_icon1 {
    width: 12px;
    height: 20px;
    margin-left: 25%;
    margin-top: 57%;
}

.sj {
    margin-top: 0.4rem;
    margin-left: 0.5rem;
    font-weight: bold;
}

.ka {
    float: left;
    margin-top: 0.4rem;
    margin-left: 0.5rem;
}

.am-card-header-content img {
    margin-right: 5px;
    width: 22px;
}

.zpMe {
    width: 25px;
    height: 25px;
    margin-left: 30%;
}

.mine_icon2 {
    width: 12px;
    height: 20px;
    margin-left: 2.5rem;
}

.mine_icon3 {
    width: 12px;
    height: 20px;
}

.topDiv {
    margin-top: 20%;
}

.textDiv {
    margin-top: 4%;
}

.textDiv1 {
    margin-top: 24%;
}

.topDiv1 {
    margin-top: 0.23rem;
}

.pjMe {
    width: 22px;
    height: 22px;
}

.my-accordion .pad .am-accordion-content-box {
    padding: 10px;
}

.dibu {
    background-image: url("../imgs/di.png");
    background-repeat: no-repeat; /*设置图片不重复*/
    background-position: 0px 0px; /*图片显示的位置*/
    height: 83px;
    bottom: 0;
    position: fixed;
    width: 100%;
    background-size:100% 100%;
    z-index: 98;
   flex-direction: column;
}
.rightDiv{
    margin-left: 1rem;
    margin-top: 0.8rem;
}
.leftDiv{
    margin-left: 1rem;
    margin-top: 0.8rem;
}
.zj{
    margin-left: 0.36rem;
    margin-top: 0.142rem;
}
.tap{
    padding-top:1cm;
    padding-bottom: 1cm;
}
.start_btn{
    float: left;
    height: 70px;
    width: 70px;
    background-size: 100%;
    background-image: url("../imgs/yynn.png");
    margin-left: 1rem;
}
.qx{
    margin-left: 0.5rem;
    float: left;
    height: 70px;
    width: 70px;
    background-size: 100%;
    background-image: url("../imgs/qx.png");
}
.fs{
    float: right;
    height: 70px;
    width: 70px;
    background-size: 100%;
    background-image: url("../imgs/fs.png");
    margin-right: 0.2rem;
}
/*body{*/
    /*-moz-user-select:none !important;*/
    /*-webkit-user-select:none!important;*/
/*}*/
.ct{
    height: 340px;
    width: 96%;
    z-index: 9999;
    /*-moz-user-select:none !important;*/
    /*-webkit-user-select:none!important;*/
}
