.me {
    margin: 0.3rem 0.3rem;
    width: 50px;
    border-radius: 135%;
    overflow: hidden;
    height: 50px;
}
.am-list-header{
    padding: 1px 15px 9px 15px!important;
    font-size: 14px;
    color: #888;
    width: 100%;
    box-sizing: border-box;
}
.am-list-item {
    position: relative;
    display: flex;
    padding-left: 15px;
    min-height: 44px;
    vertical-align: middle;
    overflow: hidden;
    transition: background-color 200ms;
    align-items: center;
}
