/* 微信气泡 */
div.speech {
    float: left;
    margin: 10px 0;
    padding: 8px;
    table-layout: fixed;
    word-break: break-all;
    position: relative;
    background: -webkit-gradient( linear, 50% 0%, 50% 100%, from(#ffffff), color-stop(0.1, #ececec), color-stop(0.5, #dbdbdb), color-stop(0.9, #dcdcdc), to(#8c8c8c) );
    border: 1px solid #989898;
    border-radius: 8px;
}
div.speech:before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    left: -21px;
    top: 5px;
    border: 10px solid;
    border-color: transparent #989898 transparent  transparent;
}
div.speech:after {
    content: '';
    position: absolute;
    width: 0;
    height: 1px;
    left: -17px;
    top: 7px;
    border: 8px solid;
    border-color: transparent #ffffff transparent  transparent;
}

div.speech.right:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    top: 10px;
    bottom: auto;
    left: auto;
    right: -8px;
    border-width: 8px 0 8px 9px;
    border-color: transparent #bced50;
}
div.speech.right {
    display: inline-block;
    box-shadow: -2px 2px 5px #CCC;
    margin-right: 10px;
    max-width: 75%;
    float: right;
    background: -webkit-gradient( linear, 50% 0%, 50% 100%, from(#e4ffa7), color-stop(0.1, #bced50), color-stop(0.4, #aed943), color-stop(0.8, #a7d143), to(#99BF40) );
}
div.speech.right:before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    top: 9px;
    bottom: auto;
    left: auto;
    right: -10px;
    border-width: 9px 0 9px 10px;
    border-color: transparent #989898;
}

div .leftLogin {
    display: inline-block;
    box-shadow: 2px 2px 2px #CCCCCC;
    margin-left: 10px;
    max-width: 75%;
    position: relative;
    background: -webkit-gradient( linear, 50% 0%, 50% 100%, from(#ffffff), color-stop(0.1, #eae8e8), color-stop(0.4, #E3E3E3), color-stop(0.8, #DFDFDF), to(#D9D9D9) );
}
div .left:before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    top: 9px;
    bottom: auto;
    left: -10px;
    border-width: 9px 10px 9px 0;
    border-color: transparent #989898;
}
div .left:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    top: 10px;
    bottom: auto;
    left: -8px;
    border-width: 8px 9px 8px 0;
    border-color: transparent #eae8e8;
}
.leftimg {
    float: left;
    margin-top: 10px;
}
.rightimg {
    float: right;
    margin-top: 10px;
}
.leftd {
    clear: both;
    margin-left: 10px;
}
.rightd {
    clear: both;
    margin-right: 10px;
}

.leftd_h{
    width: 39px;
    height: 39px;
    border-radius: 100%;
    display: block;
    float: left;
    overflow: hidden;
}

.leftd_h img{
    display: block;
    width: 100%;
    height: auto;
}
.rightd_h{
    width: 39px;
    height: 39px;
    border-radius: 100%;
    display: block;
    float: right;
    overflow: hidden;
}

.rightd_h img{
    display: block;
    width: 100%;
    height: 100%;
}