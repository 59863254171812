
.chat-input-wrapper {
    box-sizing: border-box;
    background-color: #f0f2f5;
}

.daohang {
    background-color: #fafafa;;
    position: fixed;
    width: 100%;
    bottom: 0;
    z-index: 98;
}

.img1 {
    height: 50px;
    width: 50px;
    margin-left: 28%;
}

.box1 {
    float: left;
    background-color: white;
    width: 43%;
    margin-top: 37px;
}

.box {
    background-color: red;
    width: 100%;
}

.span {
    font-size: 11px;
    margin-left: 40%;
}

.span1 {
    color: #FFD666;
    margin-left: 4%;
    font-size: 11px;
}

.span2 {
    margin-left: 30%;
    font-size: 11px;
}

.img2 {
    margin-left: 40%;
    margin-top: 17%;
}

.img22 {
    margin-left: 30%;
    margin-top: 3%;
}

.img3 {
    background-color: #FFD666;
    position: fixed;
    bottom: 3%;
    left: 41%;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    z-index: 99;
}

.box2 {
    float: left;
    background-color: white;
    width: 14%;
    margin-top: 17px;
}

.img {
    height: 25px;
    width: 25px;
    margin-top: 17%;
}

#emoji-picker-content-warpper {
    padding: 5px 4px 10px 4px;
}

.chat-textarea-box {
    width: 100%;
    height: 100%;
    position: relative;
    min-height: 100%;
    border-radius: 5px;
    overflow: hidden;
    box-sizing: border-box;
    height: 32px;
}

.duihua {
    float: left;
}

/*图片的样式*/
.contentDiv img {
    width: 100%;
    min-height: 100%;
}

.tip {
    border-color: #ddd;
    background-color: #fff;
    color: #444;
    position: relative;
    z-index: 2;
    display: block;
    margin: 0px;
    padding: 5px 10px;
    border-width: 1px;
    border-style: solid;
    font-size: 13px;
    text-align: center;
    line-height: 2.2;
}

.img-right {
    padding-top: 8px;
    padding-right: 106px;
    min-height: 100px;
    border: none;
    background-color: #f0f2f5;
}

.img-right > img:first-child {
    position: absolute;
    top: 15px;
    right: 30px;
    max-width: 50px;
    max-height: 50px;
    width: 100%;
    height: 100%;
}

.img-left {
    padding-top: 8px;
    padding-left: 106px;
    min-height: 100px;
    border: none;
    background-color: #f0f2f5;
}

.img-left > img:first-child {
    position: absolute;
    top: 10px;
    left: 30px;
    max-width: 50px;
    max-height: 50px;
    width: 100%;
    height: 100%;
}

.dialogue-left {
    margin-top: 10px;
    padding: 5px;
    background: #FFFFFF;
    border-radius: 3px;
    min-height: 35px;

}

.dialogue-left span:before {
    content: ' ';
    display: inline-block;
    position: absolute;
    right: 93px;
    top: 28px;
    width: 0;
    height: 0;
    border-width: 7px;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #e5e5e5 #e5e5e5;
}

.dialogue-right {
    margin-top: 3px;
    padding: 5px;
    background: #FFFFFF;
    border-radius: 3px;
    min-height: 35px
}

.dialogue-right span:before {
    content: ' ';
    display: inline-block;
    position: absolute;
    left: 93px;
    top: 20px;
    width: 0;
    height: 0;
    border-width: 7px;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0) #e5e5e5 #e5e5e5 rgba(0, 0, 0, 0);
}

.my-chat-box {
    background-color: #F1F2F6;
}

#oval {
    width: 82%;
    height: 49px;
    background: #FFFFFF;
    border-radius: 270px / 252px;
    margin-left: 20px;
    margin-top: 20px;
}

.text {
    float: left;
    line-height: 4;
    width: 85%;
    text-align: center;
    font-size: 13px;
}

.text1 {
    float: left;
    line-height: 3;
    width: 85%;
    text-align: center;
    background-color: #E3E3E3;
}

.contentDiv {
    display: inline-block;
    width: 40px;
    height: 40px;
    border-radius: 100px;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    border: 2px solid #fff;
    box-shadow: 0 0 4px #ccc;
    overflow: hidden;
    margin-left: 20%;
}

.contentDiv1 {
    display: inline-block;
    margin: 3px auto;
    width: 40px;
    height: 100%;
    border-radius: 100px;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    border: 2px solid #fff;
    box-shadow: 0 0 4px #ccc;
    overflow: hidden;
}

.card {
    margin-top: 28px;
    width: 88%;
    margin-left: 20px;
}

.MuiTypography-body1 {
    font-size: 1rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 2.5;
    letter-spacing: 0.00938em;
}

.ziti {
    font-size: 10px;
    color: #ADB1AF;
}

.xuqiu {
    line-height: 3.8;
}

.baomu {
    font-size: 16px;
    margin-left: 7%;
    color: #ADB1AF;
}

.dizi {
    color: #ADB1AF;
    font-size: 10px;
    margin-left: 8px;
}

#juli {
    margin-left: 10%;
    font-weight: bold;
    line-height: 3;
}

#button {
    float: left;
    margin-top: 8px;
    margin-left: 112px;

}

#circle {
    width: 45px;
    height: 45px;
    background: #FFD666;
    -moz-border-radius: 60px;
    -webkit-border-radius: 60px;
    border-radius: 60px;
    text-align: center;
    line-height: 3.6;
    font-size: 13px;
    color: #ffffff;
}

.addr {
    height: 50px;
    margin-top: 13px;

}

.button1 {
    border: 0;
    background-color: transparent;
    outline: none;
    color: #FFD666;
    line-height: 3;
}

.MuiOutlinedInput-input {
    padding: 18.5px 35px;
}

#fasong {
    float: right;
}

.fasong1 {
    font-size: 15px;
    border: 0;
    background-color: transparent;
    outline: none;
    color: #FFD666;
    width: 42px;
    margin-right: 45px;
    line-height: 6;
}

.div {
    /*overflow-x: hidden;*/
    /*height: 550px;*/
    padding-top:1cm;
    padding-bottom: 5.1cm;
}

.textAreaDiv {
    text-align: center;
}

.textArea {
    width: 98%;
    height: 65px !important;
    max-height: 100px;
    background-color: #F1F2F6;
    margin-top: 15px;
    color: #232323;
    font-weight: bold;
    border: none;
    border-radius: 10px;
    margin-left: 10px;
}

.biaoqing {
    width: 22px;
    margin-top: 10px;
}

.biaoqing1 {
    width: 18px;
    margin-top: 10px;

}

.img5 {
    width: 100%;
}

.dian {
    text-align: center;
    color: #FFD666;
    line-height: 1;
    font-size: 42px;
}

.ann {
    text-align: center;
    height: 60px;
    width: 60px;
    margin-top: 15px;
}

.img6 {
    height: 60px;
    width: 60px
}
.icon-user {
    position: absolute;
    left: 0;
    z-index: 5;
    margin-top: 8px;
    background-image: url(../imgs/me.png); /*引入图片图片*/
    background-repeat: no-repeat; /*设置图片不重复*/
    background-position: 0px 0px; /*图片显示的位置*/
    width: 40px; /*设置图片显示的宽*/
    height: 35px; /*图片显示的高*/
}

#oval1 {
    height: 300px;
    background: #FFFFFF;
    border-radius: 270px / 252px;
    margin-left: 20px;
    margin-top: 20px;
    border: none;
    position: relative;
    z-index: 1
}

.ant-card-body {
    padding: 0px !important;
    zoom: 1;
}

.km {
    margin-top: 2%;
}

.red {
    height: 14px;
    width: 10px;
    margin-left: 58%;
    margin-top: 5px
}

.hdw {
    height: 17px;
    width: 17px;
    margin-left: 50%;
    margin-top: 25%;
}

.chat-msg {
    position: relative;
    background-color: yellowgreen;
    line-height: 36px;
    padding: 0 10px;
    border-radius: 12px;
    display: inline-block;
    font-size: 12px;
    font-weight: bold;
    max-width: 94%;
}

.chat-msg1 {
    position: relative;
    background-color: yellowgreen;
    line-height: 36px;
    padding: 0 10px;
    border-radius: 12px;
    display: inline-block;
    font-size: 12px;
    font-weight: bold;
    max-width: 94%;
    text-align: left;
}

.arrow-right {
    position: absolute;
    top: 7px;
    right: -18px;
    width: 0;
    height: 0;
    border: 10px solid;
    border-color: transparent;
    border-left-color: yellowgreen !important;
    display: inline-block;
}

.arrow-left {
    position: absolute;
    top: 11px;
    left: -19px;
    width: 0;
    height: 0;
    border: 10px solid;
    border-color: transparent;
    border-right-color: yellowgreen !important;
    display: inline-block;
}

.line {
    line-height: 20px;
}





