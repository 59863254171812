

.zp{
    width: 50px;
    height:50px;
    margin-left: 30%;
    background: url("../imgs/detail/tk.png");
    background-size:46px 46px;

}
.xj{
    width: 50px;
    height:50px;
    margin-left: 30%;
    background: url("../imgs/detail/xj.png");
    background-size:46px 46px;
}
.card{
    margin-left: 7%;
    margin-right: 7%;
    margin-top: 10%;
}
.top {
     background-color: #fff;
     color: #108ee9;
     top: 0;
     position: fixed;
     width: 100%;
     z-index: 98;
 }