.am-badge-dot {
    position: absolute;
    transform: translateX(-50%);
    transform-origin: 0 center;
    top: 11px;
    height: 8px;
    width: 8px;
    border-radius: 100%;
    background: #ff5b05;
    z-index: 10;
    margin-left: 17%;
}
.badge{
   width: 100%;
}

